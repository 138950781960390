<template>
  <nav class="navigation-bar">
    <div class="navigation-bar__target"></div>

    <ul class="navigation-bar__links">
      <li v-for="(link, index) in links" :key="`link-${index}`" class="navigation-bar__item">
        <NuxtLink
          class="navigation-bar__link"
          :class="{ 'router-link-active': isActive(link) }"
          :to="link.href">
          <div class="icon">
            <CartBasketIndicator
              v-if="count > 0 && link.label === SHOPPINGCART_LABEL"
              :in-navigation-bar="true" />

            <ImagesSvg width="24" height="24" :icon="link.icon" />
          </div>
          <span> {{ link.label }} </span>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { Icon } from '@/types/icon';

interface NavigationLink {
  href: string;
  label: string;
  icon: Icon;
  activeOn: string[];
  count?: number;
}

const SHOPPINGCART_LABEL = 'Winkelwagen';

const route = useRoute();

const store = useBasketStore();
const { count } = storeToRefs(store);

const links = ref<NavigationLink[]>([
  { label: 'Home', href: '/', icon: 'home', activeOn: ['index'] },
  {
    label: 'Assortiment',
    href: '/categories',
    icon: 'wrench',
    activeOn: ['categories', 'search', 'products'],
  },
  {
    label: 'Vestigingen',
    href: '/locations',
    icon: 'marker',
    activeOn: ['locations'],
  },
  {
    label: 'Acties',
    href: '/sales',
    icon: 'percent',
    activeOn: ['sales', 'news'],
  },
  {
    label: SHOPPINGCART_LABEL,
    href: '/cart',
    icon: 'cart',
    activeOn: ['cart'],
    count: count.value,
  },
]);

function isActive(link: NavigationLink): boolean {
  const currentRouteName = route.name?.toString().split('-')?.[0];

  if (!currentRouteName) {
    return false;
  }

  return link.activeOn.includes(currentRouteName);
}
</script>

<style lang="scss" scoped>
.navigation-bar__target {
  position: relative;
  width: calc(100% + px(16));
  left: -#{px(8)};
}

.navigation-bar {
  position: fixed;
  background-color: $c-white;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-inline: px(8);
  box-shadow: 0 2px 16px rgba(0, 77, 56, 0.21);
  z-index: 5;

  &::before {
    content: '';
    top: 0;
    left: px(-8);
    position: absolute;
    height: 1px;
    width: calc(100% + (2 * px(8)));
    display: block;
    background: $c-neutral-2;
  }

  &__links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: px(4);
    background-color: $c-white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c-white;
      z-index: 0;
    }

    .icon {
      font-size: 0;
      line-height: 1;
      position: relative;
    }
  }

  &__item {
    flex: 1;
    position: relative;
  }

  &__link {
    padding-top: px(10);
    padding-inline: px(6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    border-radius: 0 0 px(8) px(8);
    color: $c-black;
    font-size: px(12);
    line-height: px(22);
  }

  .router-link-active {
    background: $c-green-3;
    color: $c-white;
    z-index: 5;
    position: relative;
  }
}
</style>
