<template>
  <div class="layout-wrapper">
    <main class="bg-neutral-1 has-navigation flex-1">
      <slot></slot>
    </main>
    <LayoutBottomNavigation />
  </div>
</template>

<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
